const __pages_import_0__ = () => import("/src/pages/events/index.vue");
const __pages_import_1__ = () => import("/src/pages/error/503.vue");
const __pages_import_2__ = () => import("/src/pages/error/500.vue");
const __pages_import_3__ = () => import("/src/pages/tickets.vue");
import __pages_import_4__ from "/src/pages/index.vue";
const __pages_import_5__ = () => import("/src/pages/contact.vue");
const __pages_import_6__ = () => import("/src/pages/[...all].vue");
const __pages_import_7__ = () => import("/src/pages/events/[slug].vue");
const __pages_import_8__ = () => import("/src/pages/pages/[slug].vue");

const routes = [{"name":"events","path":"/events","component":__pages_import_0__,"props":true},{"name":"error-503","path":"/error/503","component":__pages_import_1__,"props":true,"meta":{"layout":"error"}},{"name":"error-500","path":"/error/500","component":__pages_import_2__,"props":true,"meta":{"layout":"error"}},{"name":"tickets","path":"/tickets","component":__pages_import_3__,"props":true},{"name":"index","path":"/","component":__pages_import_4__,"props":true},{"name":"contact","path":"/contact","component":__pages_import_5__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_6__,"props":true,"meta":{"layout":"error"}},{"name":"events-slug","path":"/events/:slug","component":__pages_import_7__,"props":true},{"name":"pages-slug","path":"/pages/:slug","component":__pages_import_8__,"props":true}];

export default routes;