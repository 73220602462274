import { defineStore } from 'pinia'

import { STORES } from '~/stores/types'
import { getSearchResults } from '~/api/core/search'
import type { SearchResult } from '~/types'

interface SearchState {
  query: string
  isActive: boolean
  isSearching: boolean
  results: Array<SearchResult>
}

export default defineStore(STORES.SEARCH, {
  state: (): SearchState => ({
    query: '',
    isActive: false,
    isSearching: false,
    results: [],
  }),
  actions: {
    async fetchResults() {
      try {
        this.isSearching = true
        const { data } = await getSearchResults(this.query)
        this.results = data
      } finally {
        this.isSearching = false
      }
    },
  },
})
