<script setup lang="ts">
import { useHead } from '@vueuse/head'

import LogoLemanExperiences from '~/assets/logo-leman-experiences.png'
import { ROUTES } from '~/router/routes'
import { SOCIAL_MEDIAS } from '~/common/types'

useHead({
  title: '404 | Léman Expériences',
})
</script>

<template>
  <div class="flex-1 pt-16 pb-12 flex flex-col">
    <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0 flex justify-center">
        <router-link :to="ROUTES.HOME"
                     class="inline-flex"
        >
          <span class="sr-only">Léman Expériences</span>
          <img class="w-auto h-12"
               :src="LogoLemanExperiences"
               alt="Logo Léman Expériences"
          >
        </router-link>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p class="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
            Code 404
          </p>
          <h1 class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Page introuvable
          </h1>
          <p class="mt-2 mb-10 text-base text-gray-500">
            Désolé, cette page n'existe pas ou a été déplacée.
          </p>
          <router-link :to="ROUTES.HOME"
                       class="underline hover:text-primary-500"
          >
            Retour à l'accueil
          </router-link>
        </div>
      </div>
    </main>
    <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-center space-x-4">
        <router-link :to="ROUTES.CONTACT"
                     class="text-sm font-medium text-gray-500 hover:text-gray-600"
        >
          Nous contacter
        </router-link>
        <span class="inline-block border-l border-gray-300"
              aria-hidden="true"
        />
        <a :href="SOCIAL_MEDIAS.FACEBOOK"
           class="text-sm font-medium text-gray-500 hover:text-gray-600"
        >
          Facebook
        </a>
      </nav>
    </footer>
  </div>
</template>
