import { ref } from 'vue'

const ACTION_KEY_DEFAULT = 'Ctrl'
const ACTION_KEY_APPLE = '⌘'

export default function useActionKey() {
  const actionKey = ref('')

  if (typeof navigator !== 'undefined') {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform))
      actionKey.value = ACTION_KEY_APPLE
    else
      actionKey.value = ACTION_KEY_DEFAULT
  }

  return {
    actionKey,
  }
}
