import LayoutError from '~/layouts/layout-error.vue'
import LayoutDefault from '~/layouts/layout-default.vue'

export enum Layout {
  DEFAULT = 'default',
  ERROR = 'error',
}

export function resolveLayout(layout: Layout | any) {
  switch (layout) {
    case Layout.ERROR:
      return LayoutError
    case Layout.DEFAULT:
    default:
      return LayoutDefault
  }
}
