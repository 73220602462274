import type { ApiResponse, Event } from '@lemanexperiences/events-types'
import type { AxiosResponse } from 'axios'
import { merge } from 'lodash-es'

import axios from './'
import type { RequestParams } from '~/api/core/types'
import { formatRequestParams } from '~/api/core/utilities'

export async function getEvent(slug: string, params: RequestParams): Promise<AxiosResponse<ApiResponse<Event>>> {
  return await axios.get(`/events/${slug}?${formatRequestParams(params)}`)
}

export async function getEvents(params: RequestParams): Promise<AxiosResponse<ApiResponse<Event[]>>> {
  const query = formatRequestParams(merge({
    populate: ['thumbnail', 'address', 'performances'],
    ...params,
  }))

  return await axios.get(`/events?${query}`)
}
