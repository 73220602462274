import { createHead } from '@vueuse/head'

import type { Plugin } from '~/types'

/**
 * @param AppContext
 * @see https://github.com/vueuse/head
 */
export const install: Plugin = ({ app }) => {
  const head = createHead({
    title: 'Léman Expériences',
  })

  app.use(head)
}
