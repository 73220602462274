import type { EventType } from '@lemanexperiences/events-types/dist/collections/event'

function asRoute<T extends Record<string, string | Function>>(arg: T): T & Record<string, string | Function> {
  return arg
}

export const ROUTES = asRoute({
  HOME: '/',

  ERROR_500: '/error/500',
  ERROR_503: '/error/503',
  ERROR_404: '/error/404',

  CONTACT: '/contact',

  EVENTS: '/events',
  DATES: '/dates',

  TICKETS: '/tickets',

  EVENT_SLUG: (slug: string) => `/events/${slug}`,
  EVENTS_TYPE: (type: EventType) => `/events?type=${type}`,

  COOKIE_POLICY: '/pages/mentions-legales',
  TERMS: '/pages/mentions-legales',
  ENTERPRISES: '/pages/entreprises',
  SALES_TERMS: '/pages/conditions-generales-de-vente',
  CONCEPT: '/pages/concept',
  PRESS: '/pages/revue-presse',
})

export const STATIC_ROUTES = [
  ROUTES.HOME,
  ROUTES.ERROR_404,
  ROUTES.ERROR_500,
  ROUTES.ERROR_503,
  ROUTES.EVENTS,
  ROUTES.DATES,
]
