import { type Event, EventType, type Performance } from '@lemanexperiences/events-types'
import type { Component } from 'vue'

import { AlertType, SearchType } from '~/types'
import { SearchResultEvent } from '~/components/search/results'

export function getEventType(event: Event): {
  singular: string
  plural: string
  color: string
} {
  return {
    [EventType.GAME]: {
      singular: 'Jeu de piste',
      plural: 'Jeux de piste',
      color: 'green',
    },
    [EventType.TOUR]: {
      singular: 'Balade & Visite',
      plural: 'Balades & Visites',
      color: 'yellow',
    },
    [EventType.WORKSHOP]: {
      singular: 'Animation festive',
      plural: 'Animations festives',
      color: 'cyan',
    },
  }[event.attributes.type]
}

export function getResultComponent(type: SearchType): Component {
  return {
    [SearchType.EVENT]: SearchResultEvent,
  }[type]
}

export function getAlertColor(type: AlertType): string {
  return {
    [AlertType.INFO]: 'blue',
    [AlertType.SUCCESS]: 'green',
    [AlertType.DANGER]: 'red',
  }[type]
}

export function filterCurrentPerformances(performances: Performance[] = []): Performance[] {
  return performances.filter(x => (new Date(x.attributes.date) > new Date()))
    .sort((a, b) => new Date(a.attributes.date).getTime() - new Date(b.attributes.date).getTime())
}
