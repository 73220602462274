<script setup lang="ts">
import { computed, onMounted, ref, toRefs } from 'vue'
import gsap from 'gsap'
import { isNumber } from 'lodash-es'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

import { AlertType } from '~/types'
import { useAlertStore } from '~/stores'
import { getAlertColor } from '~/common/filters'

interface Alert {
  id?: number
  type?: AlertType
  duration?: number | boolean
  title: string
  message: string
}

const props = withDefaults(defineProps<Alert>(), {
  id: 1,
  type: AlertType.INFO,
  duration: 5000,
})

const { id, duration, type } = toRefs(props)

const storeAlerts = useAlertStore()

const root = ref(null)

onMounted(() => {
  animate()

  if (isNumber(duration.value))
    setTimeout(() => remove(), duration.value)
})

storeAlerts.$onAction(({ name }) => {
  if (name === 'add')
    moveUp()
})

function animate() {
  if (!root.value)
    return

  gsap.fromTo(root.value, {
    duration: 0.25,
    y: '100%',
    ease: 'none',
  }, {
    duration: 0.25,
    y: '0%',
    ease: 'none',
  })
}

function remove() {
  if (!root.value)
    return

  gsap.to(root.value, {
    duration: 0.25,
    opacity: '0',
    ease: 'none',
    onComplete: () => storeAlerts.remove(id.value),
  })
}

function moveUp() {
  if (!root.value)
    return

  gsap.fromTo(root.value, {
    duration: 0.25,
    y: 50,
    ease: 'none',
  }, {
    duration: 0.25,
    y: 0,
    ease: 'none',
  })
}

const color = computed(() => getAlertColor(type.value))
</script>

<template>
  <div ref="root">
    <div :class="`text-${color}-600 bg-${color}-100 border-${color}-400 hover:bg-${color}-200`"
         class="min-w-96 flex items-center relative transition-all text-sm leading-tight cursor-pointer select-none border rounded-md shadow-md py-2 px-4"
         @click.prevent.stop="remove"
    >
      <div class="mr-4">
        <InformationCircleIcon class="h-8 w-8"
                               aria-hidden="true"
        />
      </div>
      <div class="flex-1 py-1">
        <div v-if="title"
             class="mb-1 font-bold"
        >
          {{ title }}
        </div>
        <p v-if="message"
           class="m-0"
           v-html="message"
        />
      </div>
    </div>
  </div>
</template>
