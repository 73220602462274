import type { Address, File } from '@lemanexperiences/events-types'

import { GOOGLE_MAP_URL } from '~/common/types'
import type { ImageSize } from '~/types'

export function pluralize(number: number, singular: string, plural: string): string {
  return number > 1
    ? `${number} ${plural}`
    : `${number} ${singular}`
}

export function getGoogleMapsURL(address: Address): string {
  return encodeURI(`${GOOGLE_MAP_URL}/search/?api=1&query=${address.full}&query_place_id=${address.placeId}`)
}

export function getFileUrl(thumbnail: File['attributes'], size: ImageSize | null = null) {
  const baseUrl = import.meta.env.MODE === 'production'
    ? ''
    : import.meta.env.VITE_SERVER_BASE_URL

  if (size && thumbnail.formats?.[size])
    return `${baseUrl}${thumbnail.formats[size].url}`

  return `${baseUrl}${thumbnail.url}`
}
