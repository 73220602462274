import { type RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router'
import { useEventBus } from '@vueuse/core'

import routes from '~pages'
import { EVB_SUSPENSE_RESOLVE } from '~/common/types'
import { ROUTES } from '~/router/routes'

const { on } = useEventBus(EVB_SUSPENSE_RESOLVE)

function getScrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalized, savedPosition: any) {
  if (to.params.savePosition)
    return {}

  if (savedPosition)
    return savedPosition

  return {
    left: 0,
    top: 0,
  }
}

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      if (to.hash)
        return { el: to.hash }

      // Dynamic routing
      return getScrollBehavior(to, from, savedPosition)
    }

    if (to.path === ROUTES.CONTACT) {
      return {
        left: 0,
        top: 0,
      }
    }

    return new Promise((resolve) => {
      on(() => {
        resolve(getScrollBehavior(to, from, savedPosition))
      })
    })
  },
})
