<script setup lang="ts">
import type { Event } from '@lemanexperiences/events-types'
import { capitalize } from 'lodash-es'
import { computed, toRefs } from 'vue'
import { MapPinIcon } from '@heroicons/vue/24/outline'

import { filterCurrentPerformances, getEventType } from '~/common/filters'
import { ROUTES } from '~/router/routes'
import { UiAsset } from '~/components/ui'
import { getFileUrl, pluralize } from '~/common/utils'
import { ImageSize } from '~/types'

const props = defineProps<{
  event: Event
}>()
const { event: eventData } = toRefs(props)

const typeObj = getEventType(eventData.value)
const incomingPerformances = computed(() => filterCurrentPerformances(eventData.value.attributes.performances?.data || []))
</script>

<template>
  <div class="bg-white relative flex flex-col rounded-lg border border-gray-200">
    <router-link
      class="absolute inset-0 z-10 shadow-sm rounded-lg hover:shadow-xl"
      :to="ROUTES.EVENT_SLUG(eventData.attributes.slug)"
    />
    <div class="absolute top-0 left-0 m-3 z-10">
      <router-link
        :to="ROUTES.EVENTS_TYPE(eventData.attributes.type)"
        class="block text-sm leading-none font-medium p-2 rounded-md shadow transition opacity-80 hover:opacity-100"
        :class="`bg-${typeObj.color}-600 text-${typeObj.color}-100 hover:bg-${typeObj.color}-700`"
      >
        {{ typeObj.singular }}
      </router-link>
    </div>
    <UiAsset v-if="!Array.isArray(eventData.attributes.thumbnail.data)"
             class="h-40 w-full object-cover rounded-t-md"
             :src="getFileUrl(eventData.attributes.thumbnail.data.attributes, ImageSize.MEDIUM)"
             :alt="eventData.attributes.thumbnail.data.attributes.alternativeText"
    />
    <div class="p-4 flex flex-1 flex-col">
      <span v-if="incomingPerformances.length"
            class="text-xs font-medium leading-tight text-primary-600"
      >
        {{ pluralize(incomingPerformances.length, 'date programmée', 'dates programmées') }}
      </span>
      <h2 class="mt-2 font-semibold leading-tight text-gray-900">
        {{ capitalize(eventData.attributes.name) }}
      </h2>
      <p class="mt-4 text-gray-700 text-sm">
        {{ eventData.attributes.excerpt }}
      </p>
    </div>
    <div class="border-t p-3 text-sm text-gray-600 flex">
      <MapPinIcon class="flex-shrink-0 h-5 w-5 mr-4"
                  aria-hidden="true"
      />
      <span>{{ eventData.attributes.address.state }}, {{ eventData.attributes.address.country }}</span>
    </div>
  </div>
</template>
