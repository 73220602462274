import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import type { Plugin } from '~/types'

/**
 * Vue.js wrapper for Tippy.js
 *
 * @see https://vue-tippy.netlify.app/
 * @see https://atomiks.github.io/tippyjs
 */
export const install: Plugin = ({ app }) => {
  app.use(VueTippy, {
    defaultProps: {
      allowHTML: true,
      animation: 'scale',
    },
  })
}
