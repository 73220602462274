import { createApp } from 'vue'
import axios from 'axios'

import App from './App.vue'
import router from './router'
import './css/app.scss'

import type { Plugin } from '~/types'
import { ROUTES } from '~/router/routes'

(async () => {
  const app = createApp(App)

  Object.values(import.meta.glob<{ install: Plugin }>('./plugins/*.ts', { eager: true }))
    .forEach(i => i.install?.({ app, router }))

  const isMaintenance = JSON.parse(import.meta.env.VITE_IS_MAINTENANCE_ON.toLowerCase())

  if (isMaintenance) {
    await router.push(ROUTES.ERROR_503)
  } else {
    try {
      await axios.head(import.meta.env.VITE_SERVER_BASE_URL)
    } catch (err) {
      await router.push(ROUTES.ERROR_500)
    }
  }

  router.beforeEach((to, from, next) => {
    if (!navigator.onLine && from.name)
      return

    if (isMaintenance && to.path !== ROUTES.ERROR_503) {
      next(ROUTES.ERROR_503)
      return
    }

    if (!isMaintenance && to.path === ROUTES.ERROR_503) {
      next(ROUTES.HOME)
      return
    }

    next()
  })

  app.use(router)
  await router.isReady()

  app.mount('#app')
})()
