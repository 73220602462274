import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { STORES } from '~/stores/types'
import { Currency } from '~/types'

interface AppState {
  currency: Ref<Currency>
  isAnnouncementBannerActive: Ref<boolean>
}

export default defineStore(STORES.APP, {
  state: (): AppState => ({
    currency: useLocalStorage<Currency>('currency', Currency.CHF),
    isAnnouncementBannerActive: useLocalStorage('isPakatrapBannerActive', true),
  }),
  actions: {
    closeAnnouncementBanner() {
      this.isAnnouncementBannerActive = false
    },
    setCurrency(currency: Currency) {
      this.currency = currency
    },
  },
})
