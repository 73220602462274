import type { File } from '@lemanexperiences/events-types'
import type { App } from 'vue'
import type { Router } from 'vue-router'

interface AppContext<HasRouter extends boolean = true> {
  app: App<Element>
  router: HasRouter extends true ? Router : undefined
}

export interface BreadcrumbPage {
  name: string
  path: string
}

export type Plugin = (ctx: AppContext) => void

export enum Currency {
  CHF = 'CHF',
  EUR = 'EUR',
}

export enum SearchType {
  EVENT = 'event',
}

export enum AlertType {
  INFO = 'info',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export interface Alert {
  id?: number
  type?: AlertType
  duration?: number | boolean
  title: string
  message: string
}

export interface SearchResult {
  item: {
    id: number
    name: string
    slug: string
    excerpt: string
    thumbnail?: File['attributes']
  }
  refIndex: number
  contentType: 'event'
}

export enum ImageSize {
  LARGE = 'large', // 1000px
  MEDIUM = 'medium', // 750px
  SMALL = 'small', // 500px
  THUMBNAIL = 'thumbnail', // 245px
}
