<script setup lang="ts">
const stats = [
  { name: 'Événements différents', value: '35+' },
  { name: 'Dates passées', value: '300+' },
  { name: 'Participants', value: '9.000+' },
]
</script>

<template>
  <div class="max-w-3xl mx-auto py-12 space-y-16">
    <div class="text-center">
      <h2 class="text-2xl font-heading text-white font-bold tracking-tight sm:text-3xl md:text-4xl">
        Merci pour votre confiance
      </h2>
      <p class="mt-4 text-lg text-gray-300">
        Depuis plus de 3 ans, nous créons des événements uniques de qualité
        pour vous faire découvrir le Léman sous toutes ses facettes.
      </p>
    </div>
    <div class="max-w-2xl mx-auto">
      <div class="rounded-2xl overflow-hidden">
        <div class="o-grid">
          <div v-for="(stat, index) in stats"
               :key="index"
               class="o-col-12 md:o-col-4 !p-0.5"
               :class="index === 2 ? 'sm:o-col-12' : 'sm:o-col-6'"
          >
            <div class="h-full flex flex-col space-y-1 bg-white/5 p-8">
              <span class="text-sm font-semibold text-center leading-tight text-gray-300">
                {{ stat.name }}
              </span>
              <span class="order-first text-3xl text-center font-semibold tracking-tight text-white">
                {{ stat.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
