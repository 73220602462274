<script setup lang="ts">
import type { Event } from '@lemanexperiences/events-types'
import { computed, toRefs } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { inRange } from 'lodash-es'
import { ArrowLongRightIcon } from '@heroicons/vue/24/outline'

import { ROUTES } from '~/router/routes'
import EventsGrid from '~/components/events/EventsGrid.vue'
import { BREAKPOINTS } from '~/common/types'

const props = defineProps<{
  events: Event[]
}>()

const { events } = toRefs(props)

const { width } = useWindowSize()

const slicedEvents = computed(() => {
  const end = inRange(width.value, BREAKPOINTS.MD, BREAKPOINTS.LG) ? 6 : 4

  return events.value.slice(0, end)
})
</script>

<template>
  <div class="o-container max-w-7xl sm:-translate-y-20">
    <div class="mb-8">
      <EventsGrid v-if="events.length"
                  :events="slicedEvents"
      />
    </div>
    <div class="table mx-auto">
      <router-link :to="ROUTES.EVENTS"
                   class="inline-flex px-6 py-3 space-x-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 transition bg-white hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <span>Tous les événements</span>
        <ArrowLongRightIcon class="h-5 w-5"
                            aria-hidden="true"
        />
      </router-link>
    </div>
  </div>
</template>
