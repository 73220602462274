<script setup lang="ts">
import type { Component as ComponentType, ComputedRef } from 'vue'
import { computed, nextTick, onErrorCaptured, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useEventBus } from '@vueuse/core'
import { AxiosError, HttpStatusCode } from 'axios'

import { useAlertStore } from '~/stores'
import { useProgressBar } from '~/composables'
import { TheCookieConsent } from '~/components/single-instances'
import { SearchModal } from '~/components/search'
import { STATIC_ROUTES } from '~/router/routes'
import { EVB_SUSPENSE_RESOLVE } from '~/common/types'
import { resolveLayout } from '~/layouts'
import { Error404, Error500 } from '~/components/error'
import LayoutError from '~/layouts/layout-error.vue'

const route = useRoute()
const router = useRouter()

const stores = {
  alert: useAlertStore(),
}

const layout: ComputedRef<ComponentType> = computed(() => resolveLayout(route.meta.layout))

const { emit } = useEventBus(EVB_SUSPENSE_RESOLVE)

const { done, start } = useProgressBar()

const is404Error = ref(false)
const isUncapturedError = ref(false)

onErrorCaptured((error: any) => {
  console.log('Error Captured', error)
  done()

  if (error instanceof AxiosError) {
    if (!STATIC_ROUTES.includes(route.path) && error?.response?.status === HttpStatusCode.NotFound) {
      is404Error.value = true
      return
    }
  }

  isUncapturedError.value = true
})

const isLoaded = ref(false)
const firstLoad = ref(false)

onMounted(() => {
  firstLoad.value = true
})

nextTick(async () => {
  isLoaded.value = true
})

router.afterEach((to, from) => {
  is404Error.value = false
  isUncapturedError.value = false

  // Exclude search query routing
  if (to.fullPath.split('?')[0] !== from.fullPath.split('?')[0])
    stores.alert.$reset()
})

function resolve() {
  emit()
  done()
}
</script>

<template>
  <transition v-show="firstLoad"
              appear
              enter-active-class="duration-300"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
  >
    <div>
      <LayoutError v-if="is404Error || isUncapturedError">
        <component :is="is404Error ? Error404 : Error500" />
      </LayoutError>
      <component :is="layout"
                 v-else
      >
        <router-view v-slot="{ Component }">
          <Suspense @pending="start"
                    @resolve="resolve"
          >
            <component :is="Component" />
          </Suspense>
        </router-view>
        <template v-if="isLoaded">
          <TheCookieConsent />
          <SearchModal />
        </template>
      </component>
    </div>
  </transition>
</template>

<style>
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: theme('zIndex.50');
  background: theme('colors.secondary.500');
}
</style>
`
