<script setup lang="ts">
import { EventType } from '@lemanexperiences/events-types'
import { pattern } from '~/common/types'
import { ROUTES } from '~/router/routes'
import { UiAsset } from '~/components/ui'
import { Animations, Balades, Jeux } from '~/assets/types'

const types = [
  {
    name: 'Nos Jeux de Piste',
    about: 'Tentez de résoudre nos énigmes !',
    path: ROUTES.EVENTS_TYPE(EventType.GAME),
    src: Jeux,
  },
  {
    name: 'Nos Animations Festives',
    about: 'Découvrez nos événements inédits !',
    path: ROUTES.EVENTS_TYPE(EventType.WORKSHOP),
    src: Animations,
  },
  {
    name: 'Nos Balades & Visites',
    about: 'Explorez des parcours uniques !',
    path: ROUTES.EVENTS_TYPE(EventType.TOUR),
    src: Balades,
  },
]
</script>

<template>
  <div class="relative">
    <div aria-hidden="true"
         class="hidden absolute inset-0 sm:flex sm:flex-col"
    >
      <div class="flex-1 relative w-full bg-gray-800">
        <UiAsset :src="pattern"
                 alt="Pattern"
                 class="object-cover h-full"
        />
      </div>
      <div class="w-full bg-white h-32 md:h-40 lg:h-48" />
    </div>

    <div class="relative max-w-3xl mx-auto pb-96 text-center sm:pb-0">
      <div aria-hidden="true"
           class="z-0 absolute inset-0 flex flex-col sm:hidden"
      >
        <div class="flex-1 relative w-full bg-gray-800">
          <div class="absolute inset-0 bg-gray-900 opacity-50" />
        </div>
        <div class="w-full bg-white h-48" />
      </div>
      <div class="o-container relative py-12">
        <h2 class="text-2xl font-heading font-bold tracking-tight text-white sm:text-3xl md:text-4xl">
          Nos événements
        </h2>
        <div class="mt-4 sm:mt-6">
          <p class="text-lg text-gray-300">
            Partez à la découverte de l'arc lemanique et de ses richesses ! Histoire et traditions, gastronomie,
            nature... évadez-vous autour du Léman !
          </p>
        </div>
      </div>
    </div>

    <div class="-mt-96 relative sm:mt-0">
      <div class="o-container max-w-md sm:max-w-7xl">
        <div class="o-grid">
          <div v-for="(type, index) in types"
               :key="type.name"
               class="o-col-12 sm:o-col-6 md:o-col-4"
               :class="index === types.length - 1 ? 'sm:o-col-12' : 'sm:o-col-6'"
          >
            <div
              class="group relative h-96 bg-white rounded-lg shadow-xl sm:h-auto sm:aspect-w-4"
              :class="index === types.length - 1 ? 'sm:aspect-h-2 md:aspect-h-5' : 'sm:aspect-h-5'"
            >
              <div aria-hidden="true"
                   class="absolute inset-0 rounded-lg overflow-hidden"
              >
                <div class="absolute inset-0 overflow-hidden group-hover:opacity-75">
                  <UiAsset :src="type.src"
                           :alt="type.name"
                           class="w-full h-full object-center object-cover"
                  />
                </div>
                <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
              </div>
              <div class="absolute inset-0 rounded-lg p-6 flex flex-col justify-end">
                <h3 class="mb-1 font-semibold text-white text-xl">
                  <router-link :to="type.path">
                    <span class="absolute inset-0" />
                    {{ type.name }}
                  </router-link>
                </h3>
                <p aria-hidden="true"
                   class="text-sm text-white"
                >
                  {{ type.about }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
