import { defineStore } from 'pinia'

import type { Alert } from '~/types'
import { STORES } from '~/stores/types'

interface AlertState {
  alerts: Alert[]
}

export default defineStore(STORES.ALERT, {
  state: (): AlertState => ({
    alerts: [],
  }),
  actions: {
    add(alert: Alert) {
      this.alerts.push(alert)
    },
    remove(id: number): void {
      this.alerts = this.alerts.filter(x => x?.id !== id)
    },
  },
})
