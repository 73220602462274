<script setup lang="ts">
import type { Event } from '@lemanexperiences/events-types'
import { type ShallowRef, shallowRef } from 'vue'
import { useHead } from '@vueuse/head'

import { HandDrawnArrow1 } from '~/assets/icons'
import {
  TheEnterprises,
  TheEventTypes,
  TheEvents,
  TheHero,
  TheStats,
} from '~/components/home'
import { getEvents } from '~/api/core/events'

useHead({
  title: 'Léman Expériences',
})

const events: ShallowRef<Event[]> = shallowRef([])

/**
 * Fetch most recent events.
 */
await (async () => {
  const { data: eventData } = await getEvents({
    pagination: {
      limit: 6,
    },
    sort: ['updatedAt:desc'],
  })
  events.value = eventData.data as Event[]
})()
</script>

<template>
  <div>
    <TheHero />

    <section v-if="events.length"
             class="pt-4 sm:-mb-20 pb-12"
    >
      <div class="o-container relative max-w-7xl">
        <div class="hidden md:block absolute bottom-28 left-12 flex flex-col items-center space-y-2">
          <span class="whitespace-nowrap font-heading text-sm text-gray-300">
            Nos événements
          </span>
          <div class="translate-x-1/2">
            <HandDrawnArrow1 aria-hidden="true"
                             class="fill-gray-300 w-12 h-auto"
            />
          </div>
        </div>
      </div>
      <TheEvents :events="events" />
    </section>

    <section class="bg-white border-t py-12">
      <div class="o-container max-w-7xl">
        <TheEnterprises />
      </div>
    </section>

    <section class="bg-white border-b border-b-2 pb-12">
      <TheEventTypes />
    </section>

    <section class="bg-gray-900">
      <div class="o-container">
        <TheStats />
      </div>
    </section>
  </div>
</template>
