<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { WifiIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { useAlertStore } from '~/stores'

const storeAlert = useAlertStore()

const isOnline = ref(true)

function setStatus() {
  isOnline.value = navigator.onLine
}

onMounted(() => {
  setStatus()
  window.addEventListener('offline', setStatus)
  window.addEventListener('online', setStatus)
})

watch(isOnline, (x) => {
  if (x) {
    storeAlert.add({
      title: 'Info',
      message: 'Votre connexion Internet a été restaurée',
    })
    setTimeout(() => window.location.reload(), 2000)
  }
})

function reload() {
  window.location.reload()
}
</script>

<template>
  <TransitionRoot as="template"
                  :show="!isOnline"
  >
    <Dialog as="div"
            class="fixed z-40 inset-0 overflow-y-auto"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template"
                         enter="ease-out duration-300"
                         enter-from="opacity-0"
                         enter-to="opacity-100"
                         leave="ease-in duration-200"
                         leave-from="opacity-100"
                         leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
        >&#8203;</span>
        <TransitionChild as="template"
                         enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100"
                         leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <WifiIcon class="h-6 w-6 text-red-600"
                          aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3"
                             class="text-lg leading-6 font-medium text-gray-900"
                >
                  Aucune connexion internet
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Veuillez rétablir votre connexion Internet pour continuer à naviguer sur le
                    site.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button"
                      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                      @click="reload"
              >
                Réessayer
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
