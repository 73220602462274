<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import Cookies from 'js-cookie'

import { ROUTES } from '~/router/routes'
import { EVB_USER_CONSENT } from '~/common/types'

const { emit } = useEventBus(EVB_USER_CONSENT)

const hasConsent = ref(JSON.parse(Cookies.get('user-consent') || 'false'))

function accept(): void {
  hasConsent.value = true
  Cookies.set('user-consent', 'true', { expires: 365, path: '/' })
  emit()
}

// Auto accept after 10s
onMounted(() => setTimeout(accept, 10000))
</script>

<template>
  <transition appear
              enter-active-class="duration-300"
              enter-from-class="translate-y-full"
              enter-to-class="translate-0"
              leave-active-class="duration-300"
              leave-to-class="translate-y-full"
  >
    <div v-if="!hasConsent"
         class="fixed bottom-0 inset-x-0 z-50 text-center"
    >
      <div class="inline-flex flex-col lg:flex-row items-center justify-center flex-wrap py-6 px-4 lg:py-4 lg:px-6 bg-gray-800 shadow-md lg:space-x-6 w-full lg:w-auto lg:rounded-t-md">
        <p class="font-medium text-white text-xs">
          En poursuivant votre navigation, vous reconnaissez avoir lu et accepté notre
          <router-link :to="ROUTES.COOKIE_POLICY"
                       class="underline"
          >
            politique de cookie
          </router-link>
        </p>
        <div class="flex-shrink-0 mt-4 lg:mt-0">
          <button type="button"
                  class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium transition text-primary-600 bg-white hover:bg-primary-100"
                  @click="accept"
          >
            Accepter
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
