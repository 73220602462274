import { createPinia } from 'pinia'

import type { Plugin } from '~/types'

/**
 * @param AppContext
 * @see https://pinia.vuejs.org
 */
export const install: Plugin = ({ app }) => {
  const pinia = createPinia()
  app.use(pinia)
}
