import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import type { Plugin } from '~/types'
import { sentryKey } from '~/common/injection-symbols'

/**
 * @param AppContext
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/
 */
export const install: Plugin = ({ app, router }) => {
  Sentry.init({
    app,
    dsn: <string>import.meta.env.VITE_SENTRY_DNS,
    release: APP_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['http://localhost:3000/', /^\//],
      }),
    ],
    environment: import.meta.env.MODE,
    tracesSampleRate: 1.0,
    // debug: import.meta.env.MODE !== 'production',
  })

  app.config.errorHandler = (err: any) => {
    Sentry.captureException(err)
  }

  app.provide(sentryKey, Sentry)
}
