import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/fr'

import type { Plugin } from '~/types'
import { dayjsKey } from '~/common/injection-symbols'

/**
 * @param AppContext
 * @see https://day.js.org
 */
export const install: Plugin = ({ app }) => {
  dayjs.extend(weekday)
  dayjs.extend(duration)
  dayjs.locale('fr')

  app.provide(dayjsKey, dayjs)
}
