<script setup lang="ts">
import { onMounted, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useEventBus } from '@vueuse/core'
import Mousetrap from 'mousetrap'
import {
  ArrowSmallRightIcon,
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  TicketIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { useAppStore, useSearchStore } from '~/stores'
import { ROUTES } from '~/router/routes'
import LogoLemanExperiences from '~/assets/logo-leman-experiences.png'
import IconLemanExperiences from '~/assets/icon-leman-experiences.png'
import CurrencySelect from '~/components/CurrencySelect.vue'
import { useActionKey } from '~/composables'
import { EVB_SUSPENSE_RESOLVE } from '~/common/types'
import type { Currency } from '~/types'

const stores = {
  app: useAppStore(),
  search: useSearchStore(),
}

const { currency } = toRefs(stores.app)

const route = useRoute()

onMounted(() => {
  const { on } = useEventBus(EVB_SUSPENSE_RESOLVE)
  on(() => document.getElementById('closeButton')?.click())
})

const { actionKey } = useActionKey()

if (actionKey.value)
  Mousetrap.bind(['command+k', 'ctrl+k'], () => stores.search.isActive = true)

async function setCurrency(currency: Currency) {
  stores.app.setCurrency(currency)
}
</script>

<template>
  <div>
    <Popover class="relative bg-white z-20 border-b shadow-md">
      <div class="max-w-screen-2xl mx-auto">
        <div
          class="flex flex-wrap xl:flex-nowrap justify-between items-center px-2 py-2 sm:px-6 md:py-4 xl:space-x-8 xl:justify-between"
        >
          <div class="shrink-0 text-center md:mr-10 lg:w-full lg:mb-4 lg:order-first xl:w-auto xl:m-0">
            <router-link :to="ROUTES.HOME"
                         class="inline-block"
            >
              <span class="sr-only">Léman Expériences</span>
              <img class="w-auto h-9 hidden md:block"
                   :src="LogoLemanExperiences"
                   alt="Logo Léman Expériences"
              >
              <img class="w-auto h-9 md:hidden"
                   :src="IconLemanExperiences"
                   alt="Logo Léman Expériences"
              >
            </router-link>
          </div>

          <div class="order-first md:hidden">
            <button type="button"
                    class="bg-white rounded-md p-4 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:bg-transparent md:p-2 md:flex md:text-gray-500 md:focus-none md:hover:bg-transparent md:hover:text-gray-800"
                    @click="stores.search.isActive = true"
            >
              <span class="sr-only">Ouvrir la barre de recherche</span>
              <MagnifyingGlassIcon class="h-5 w-5"
                                   aria-hidden="true"
              />
            </button>
          </div>

          <div class="md:hidden">
            <PopoverButton
              class="bg-white rounded-md p-4 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            >
              <span class="sr-only">Ouvrir le menu</span>
              <Bars3Icon class="h-5 w-5"
                         aria-hidden="true"
              />
            </PopoverButton>
          </div>

          <nav class="hidden justify-center items-center space-x-6 md:flex xl:order-first">
            <router-link :to="ROUTES.EVENTS"
                         class="text-sm leading-6 font-medium whitespace-nowrap hover:text-gray-900"
                         :class="[ROUTES.EVENTS === route.path ? 'text-gray-900' : 'text-gray-500']"
            >
              Nos événements
            </router-link>
            <router-link :to="ROUTES.ENTERPRISES"
                         class="text-sm leading-6 font-medium whitespace-nowrap hover:text-gray-900"
                         :class="[ROUTES.ENTERPRISES === route.path ? 'text-gray-900' : 'text-gray-500']"
            >
              Entreprises
            </router-link>
            <router-link :to="ROUTES.PRESS"
                         class="text-sm leading-6 font-medium whitespace-nowrap hover:text-gray-900"
                         :class="[ROUTES.PRESS === route.path ? 'text-gray-900' : 'text-gray-500']"
            >
              Revue de presse
            </router-link>
            <a href="https://www.genevepascher.com/blog"
               target="_blank"
               class="text-sm flex space-x-1 leading-6 font-medium text-gray-500 whitespace-nowrap hover:text-gray-900"
            >
              <span>Blog</span>
              <ArrowTopRightOnSquareIcon class="h-4 w-4"
                                         aria-hidden="true"
              />
            </a>
          </nav>

          <div class="w-full h-0 -mx-6 border-t py-2 mt-2 hidden md:block lg:hidden" style="width: calc(100% + 3rem)" />

          <div class="pointer-events-auto hidden md:block lg:order-first xl:flex-1 order-1 md:ml-auto md:pr-4 lg:ml-0 lg:pr-0">
            <button type="button"
                    class="max-w-full w-72 xl:w-full mx-auto flex items-center border border-gray-300 text-sm text-gray-400 rounded-md py-2 px-4 pr-3 hover:bg-gray-100"
                    @click="stores.search.isActive = true"
            >
              <MagnifyingGlassIcon class="h-5 w-5"
                                   aria-hidden="true"
              />
              <span class="pl-2 pr-6 whitespace-nowrap">
                Recherche<span class="hidden xl:inline"> rapide</span>...
              </span>
              <span v-if="actionKey"
                    class="ml-auto text-xs font-semibold"
              >
                {{ actionKey }}K
              </span>
            </button>
          </div>

          <CurrencySelect :currency="currency"
                          class="hidden md:block lg:absolute top-4 left-6 xl:static"
                          @update="setCurrency"
          />

          <div class="order-2 hidden md:block">
            <router-link :to="ROUTES.TICKETS"
                         class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <TicketIcon class="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
              />
              La Billetterie
            </router-link>
          </div>
        </div>

        <PopoverPanel v-slot="{ close }"
                      focus
                      class="absolute top-0 inset-x-0 p-4 transition transform origin-top-right md:hidden"
        >
          <teleport to="body">
            <div class="inset-0 fixed bg-black bg-opacity-25" />
          </teleport>

          <div class="rounded-lg shadow-lg bg-white border">
            <div class="pt-2 pb-4 px-3 sm:px-5">
              <div class="flex items-center justify-between">
                <CurrencySelect :currency="currency"
                                @update="setCurrency"
                />
                <PopoverButton id="closeButton"
                               class="bg-white rounded-md p-2 -mr-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                >
                  <span class="sr-only">Fermer le menu</span>
                  <XMarkIcon class="h-6 w-6"
                             aria-hidden="true"
                  />
                </PopoverButton>
              </div>
            </div>
            <div class="py-6 px-2 sm:px-5">
              <nav class="flex flex-col">
                <router-link v-slot="{ href, navigate }"
                             custom
                             :to="ROUTES.EVENTS"
                >
                  <a :href="href"
                     class="border-y p-4 text-sm leading-6 font-medium hover:text-gray-900 flex items-center justify-between"
                     :class="[ROUTES.EVENTS === route.path ? 'text-gray-900' : 'text-gray-500']"
                     @click.prevent="() => { navigate(); close() }"
                  >
                    <span>Nos événements</span>
                    <ArrowSmallRightIcon class="h-6 w-6"
                                         aria-hidden="true"
                    />
                  </a>
                </router-link>
                <router-link v-slot="{ href, navigate }"
                             custom
                             :to="ROUTES.ENTERPRISES"
                >
                  <a :href="href"
                     class="border-y p-4 text-sm leading-6 font-medium hover:text-gray-900 flex items-center justify-between"
                     :class="[ROUTES.ENTERPRISES === route.path ? 'text-gray-900' : 'text-gray-500']"
                     @click.prevent="() => { navigate(); close() }"
                  >
                    <span>Entreprises</span>
                    <ArrowSmallRightIcon class="h-6 w-6"
                                         aria-hidden="true"
                    />
                  </a>
                </router-link>
                <router-link v-slot="{ href, navigate }"
                             custom
                             :to="ROUTES.PRESS"
                >
                  <a :href="href"
                     class="border-y p-4 text-sm leading-6 font-medium hover:text-gray-900 flex items-center justify-between"
                     :class="[ROUTES.PRESS === route.path ? 'text-gray-900' : 'text-gray-500']"
                     @click.prevent="() => { navigate(); close() }"
                  >
                    <span>Revue de press</span>
                    <ArrowSmallRightIcon class="h-6 w-6"
                                         aria-hidden="true"
                    />
                  </a>
                </router-link>
                <router-link v-slot="{ href, navigate }"
                             custom
                             :to="ROUTES.CONTACT"
                >
                  <a :href="href"
                     class="border-y p-4 text-sm leading-6 font-medium hover:text-gray-900 flex items-center justify-between"
                     :class="[ROUTES.CONTACT === route.path ? 'text-gray-900' : 'text-gray-500']"
                     @click.prevent="() => { navigate(); close() }"
                  >
                    <span>Nous contacter</span>
                    <ArrowSmallRightIcon class="h-6 w-6"
                                         aria-hidden="true"
                    />
                  </a>
                </router-link>
                <a href="https://www.genevepascher.com/blog"
                   target="_blank"
                   class="border-b p-4 text-sm leading-6 font-medium text-gray-500 flex items-center justify-between hover:text-gray-900"
                >
                  <div class="flex space-x-1">
                    <span>Blog</span>
                    <ArrowTopRightOnSquareIcon class="h-4 w-4"
                                               aria-hidden="true"
                    />
                  </div>
                  <ArrowSmallRightIcon class="h-6 w-6"
                                       aria-hidden="true"
                  />
                </a>
                <a href="https://www.pakatrap.com/"
                   target="_blank"
                   class="border-b p-4 text-sm leading-6 font-medium text-gray-500 flex items-center justify-between hover:text-gray-900"
                >
                  <div class="flex space-x-1">
                    <span>Festival Pakatrap</span>
                    <ArrowTopRightOnSquareIcon class="h-4 w-4"
                                               aria-hidden="true"
                    />
                  </div>
                  <ArrowSmallRightIcon class="h-6 w-6"
                                       aria-hidden="true"
                  />
                </a>
              </nav>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  </div>
</template>
