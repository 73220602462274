<script setup lang="ts">
import Alert from './Alert.vue'
import { useAlertStore } from '~/stores'

const store = useAlertStore()
</script>

<template>
  <div v-if="store.alerts.length"
       class="fixed px-8 left-1/2 bottom-8 z-60 overflow-hidden -translate-x-1/2 space-y-4 transition-all"
  >
    <Alert v-for="alert in store.alerts"
           :id="alert.id"
           :key="alert.id"
           :title="alert.title"
           :message="alert.message"
           :duration="alert.duration"
           :type="alert.type"
    />
  </div>
</template>
