import axios from 'axios'

/**
 * Strapi Rest API
 * @see https://docs.strapi.io/dev-docs/api/rest
 */

const customAxios = axios.create({
  baseURL: `${<string>import.meta.env.VITE_SERVER_BASE_URL}/api`,
  headers: {
    Authorization: `Bearer ${import.meta.env.VITE_SERVER_API_TOKEN}`,
  },
})

export default customAxios
