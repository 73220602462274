<script setup lang="ts">
import { ref, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline'

import { FranceFlag, SwissFlag } from '~/assets/icons/flags'
import { EUR_RATE } from '~/common/types'
import type { Currency } from '~/types'

const { currency = 'CHF' } = defineProps<{
  currency: Currency
}>()

const emit = defineEmits(['update'])

const currencies: {
  code: string
  country: string
  icon: Function
}[] = [
  {
    code: 'EUR',
    country: 'France',
    icon: () => FranceFlag,
  },
  {
    code: 'CHF',
    country: 'Suisse',
    icon: () => SwissFlag,
  },
]

const selectedCurrency = ref(currencies.find(x => x.code === currency))
watch(selectedCurrency, () => emit('update', selectedCurrency.value?.code))
</script>

<template>
  <div>
    <Listbox v-slot="{ open }"
             v-model="selectedCurrency"
             as="div"
    >
      <div v-tippy="{ content: () => open ? '' : 'Changer de devise', placement: 'bottom' }"
           class="relative cursor-pointer min-w-20 xl:min-w-28 text-sm"
      >
        <ListboxButton
          class="relative w-full border border-gray-300 rounded-md pl-3 pr-10 py-2 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
        >
          <div class="flex items-center">
            <component :is="selectedCurrency.icon()"
                       :alt="`Drapeau ${selectedCurrency.country}`"
                       class="h-5 w-5"
                       aria-hidden="true"
            />
            <div class="ml-3">
              {{ selectedCurrency.code }}
            </div>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                               aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition leave-active-class="duration-300"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 bg-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
          >
            <div class="p-2 whitespace-nowrap border-b font-semibold text-xs cursor-default text-gray-700">
              1 CHF = {{ EUR_RATE }} EUR
            </div>
            <ListboxOption v-for="option in currencies"
                           :key="option.code"
                           v-slot="{ active, selected }"
                           as="template"
                           :value="option"
            >
              <li class="cursor-default select-none relative py-2 pl-3 pr-9"
                  :class="[active ? 'text-white bg-primary-600' : 'text-gray-900']"
              >
                <div class="flex items-center">
                  <component :is="option.icon()"
                             :alt="`Drapeau ${option.country}`"
                             class="h-5 w-5"
                             aria-hidden="true"
                  />
                  <span class="ml-3 block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">
                    {{ option.code }}
                  </span>
                </div>
                <span v-if="selected"
                      class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-primary-600']"
                >
                  <CheckIcon class="h-5 w-5"
                             aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
