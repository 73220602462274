import type { InjectionKey } from 'vue'
import { inject } from 'vue'

declare type Sentry = typeof import('@sentry/vue')
declare type dayjs = typeof import('dayjs')

export const dayjsKey: InjectionKey<dayjs> = Symbol('dayjs')
export const sentryKey: InjectionKey<Sentry> = Symbol('sentry')

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback)

  if (!resolved)
    throw new Error(`Could not resolve ${key.description}`)

  return resolved
}
