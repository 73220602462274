<script setup lang="ts">
import { computed, toRefs, watch } from 'vue'
import { debounce } from 'lodash-es'
import { ExclamationCircleIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { useSearchStore } from '~/stores'
import { getResultComponent } from '~/common/filters'

const store = useSearchStore()

const { query, results } = toRefs(store)

const hasResults = computed(() => !!results.value.length)

const fetchResult = debounce(async () => await store.fetchResults(), 500)

watch(query, (v) => {
  if (v.length > 2)
    fetchResult()
  else
    results.value = []
})

function closeSearch() {
  store.isActive = false
}

function setSearchQuery(value: string) {
  store.query = value
}

function reset() {
  store.$reset()
}

function onSelect() {
  closeSearch()
}
</script>

<template>
  <TransitionRoot :show="store.isActive"
                  as="template"
                  appear
                  @after-leave="reset"
  >
    <Dialog class="relative z-20"
            @close="closeSearch"
    >
      <TransitionChild as="template"
                       enter="ease-out duration-300"
                       enter-from="opacity-0"
                       enter-to="opacity-100"
                       leave="ease-in duration-200"
                       leave-from="opacity-100"
                       leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template"
                         enter="ease-out duration-300"
                         enter-from="opacity-0 scale-95"
                         enter-to="opacity-100 scale-100"
                         leave="ease-in duration-200"
                         leave-from="opacity-100 scale-100"
                         leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox @update:model-value="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                     aria-hidden="true"
                />
                <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                               placeholder="Rechercher un événement..."
                               @keydown.esc="closeSearch"
                               @change="setSearchQuery($event.target.value)"
                />
              </div>

              <ComboboxOptions v-if="hasResults"
                               static
                               class="max-h-96 scroll-py-3 overflow-y-auto p-3"
              >
                <ComboboxOption v-for="({ item }) in store.results"
                                :key="item.id"
                                v-slot="{ active }"
                                :value="item"
                                as="template"
                >
                  <li class="cursor-default select-none"
                      :class="{ 'bg-gray-100': active }"
                  >
                    <component :is="getResultComponent(item.contentType)"
                               :item="item"
                               :active="active"
                               @navigate="closeSearch"
                    />
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-else
                   class="py-14 px-6 text-center text-sm sm:px-14"
              >
                <ExclamationCircleIcon class="mx-auto h-6 w-6 text-gray-400"
                                       aria-hidden="true"
                />
                <p class="mt-4 font-semibold text-gray-900">
                  <template v-if="store.isSearching">
                    Chargement des resultats...
                  </template>
                  <!-- TODO: handle state while debouncing -->
                  <template v-else-if="store.query.length > 3">
                    Aucun résultat ne correspond à votre recherche
                  </template>
                  <template v-else>
                    Veuillez saisir un mot clé contenant au moins 3 caractères
                  </template>
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
